import { useState } from 'react';
import { Flex, Modal, ModalBody, ModalContent } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useModalDisclosure } from '@arena-labs/strive2-ui';
import { WearableProviders } from '@strive/api';

import { ConfirmOrEditAddress } from './confirm-or-edit-address';
//comments are probably temporary
// import { SelectStrivewareRingStyle } from './select-finish';
import { SelectSize } from './select-size';
import { ShipmentConfirmed } from './shipment-confirmed';
import { SizeSelectionWelcome } from './size-selection-welcome';

RingSizeSelection.useDisclosure = function () {
  return useModalDisclosure('Ring Size Selection');
};

export function RingSizeSelection({
  wearableProvider,
  isOpen,
  onClose,
}: {
  wearableProvider: WearableProviders;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [ringSizeSelected, setRingSizeSelected] = useState<number>();
  const [
    strivewareStyleSelected,
    //  setStrivewareStyleSelected
  ] = useState<'Matte Black' | 'Rose Gold' | undefined>(
    wearableProvider === 'striveware' ? 'Matte Black' : undefined,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalContent m={0} bgGradient={'none'}>
        <ModalBody m={0} p={0} bgGradient={'none'}>
          <Flex h={'full'} maxH={'100vh'} direction={'row'}>
            <Swiper allowTouchMove={false}>
              <SwiperSlide key="welcome">
                <SizeSelectionWelcome
                  onClose={() => onClose()}
                  wearableProvider={wearableProvider}
                />
              </SwiperSlide>
              {/* {wearableProvider === 'striveware' && (
                <SwiperSlide key="striveware-style">
                  <SelectStrivewareRingStyle
                    selection={strivewareStyleSelected}
                    onSelect={setStrivewareStyleSelected}
                  />
                </SwiperSlide>
              )} */}

              <SwiperSlide key="ring-size">
                <SelectSize
                  selection={ringSizeSelected}
                  onSelect={setRingSizeSelected}
                  wearableProvider={wearableProvider}
                />
              </SwiperSlide>
              {ringSizeSelected && (
                <>
                  <SwiperSlide
                    key="confirm-or-edit-address"
                    style={{
                      paddingTop: 'env(safe-area-inset-top)',
                      paddingBottom: 'env(safe-area-inset-bottom)',
                    }}
                  >
                    <ConfirmOrEditAddress
                      ringSize={ringSizeSelected}
                      wearableProvider={wearableProvider}
                      ringStyle={strivewareStyleSelected}
                    />
                  </SwiperSlide>
                  <SwiperSlide key="shipment-confirmed">
                    <ShipmentConfirmed
                      onClose={onClose}
                      wearableProvider={wearableProvider}
                      selection={strivewareStyleSelected ?? 'Matte Black'}
                    />
                  </SwiperSlide>
                </>
              )}
            </Swiper>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
