import { VStack } from '@chakra-ui/react';

import { ProductEducationPopover, TEXT } from '@arena-labs/strive2-ui';

import { AciCTACard } from './aci-cta-card';

export function AciCtaWidget() {
  return (
    <VStack gap={1}>
      <TEXT.P1_SEMIBOLD mr={'auto'}>Daily Action</TEXT.P1_SEMIBOLD>

      <ProductEducationPopover
        anchorXPaddingModifier={2}
        id={'ACI_Two'}
        placement={'top'}
        shouldRender={true}
        title={'Start Arena Check-In'}
        body={`It's not a bad idea to start by checking in with yourself!`}
      >
        <AciCTACard />
      </ProductEducationPopover>
    </VStack>
  );
}
