import Head from 'next/head';
import { Fade } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { AnalyticsContext, CoachingTrackingTag } from '@arena-labs/analytics';
import { useHomepage } from '@arena-labs/strive2-coaching';
import {
  LoadingOrError,
  PageStack,
  ProductEducationPopover,
  SlidePresence,
  useEducationPopoverViewed,
} from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';

import { useAppGateIsOpen } from '../gates/app-gate-listener';
import { AppLayout } from '../layout/app-layout';
import { HomeBanner } from '../layout/home-banner/home-banner';
import LogCurrentPractice from '../learning-session/log-current-practice';
import PracticeFreeSpace from '../learning-session/practice-free-space';
import { SessionMedia } from '../learning-session/session-media';
import { PracticeSetSelection } from '../practice-set/practice-set-selection';
import { HomeHub } from './hub/home-hub';
import { SessionTracker } from './session-tracker';
import { TeamVideoModal } from './team-video-modal';

export function CoachingHome() {
  const [homepage, { state, send }] = useHomepage();
  const pillarData = homepage?.pillar_data;
  const { data: user } = useUserProfile();
  const pillar = pillarData?.current_pillar;
  const learningSession = homepage?.learning_session;
  const isAppGateOpen = useAppGateIsOpen();
  const currentPractice = learningSession?.practices.at(-1);
  const currentPracticeImage = currentPractice?.background_image;
  const hasSeenACITooltip = useEducationPopoverViewed('ACI_Two')[0];

  if (!pillar || !learningSession) {
    return (
      <LoadingOrError
        status="error"
        errorMessage="There was a problem loading your learning session."
      />
    );
  }

  if (state.matches('Landing Hub.show')) {
    return (
      <HomeHub
        practiceSetName={pillar.short_title}
        bgImagePath={currentPracticeImage}
        userName={user?.first_name}
        practiceName={currentPractice?.short_title}
        willSelectPracticeSet={learningSession.state === 'select_pillar'}
      ></HomeHub>
    );
  }

  if (state.matches('Choose your learning journey.Choose practice set')) {
    return <PracticeSetSelection onBack={() => send('Go Back')} />;
  }

  return (
    <AppLayout banner={<HomeBanner title={pillar.short_title} />}>
      <Head>
        <title>Arena Strive</title>
      </Head>
      <AnalyticsContext
        context={{
          pillar: pillar.order + 1,
          pillarSlug: pillar.slug,
          session: pillarData.session,
        }}
      >
        <PageStack
          pt="4"
          pb="6"
          spacing="6"
          align="stretch"
          overflowY="auto"
          overflowX="hidden"
        >
          <ProductEducationPopover
            anchorXPaddingModifier={-1}
            id={'Daily_Tracker'}
            placement={'bottom'}
            shouldRender={
              !state.matches('Team Video.open') && hasSeenACITooltip
            }
            shouldOutline
            title={'Daily Progress Tracker'}
            body={'You are here in your daily learning.'}
          >
            <SessionTracker />
          </ProductEducationPopover>

          <SlidePresence skipInitialTransition h="full">
            {isAppGateOpen ? null : state.matches('Team Video.open') ? (
              <motion.div key="team-video">
                <TeamVideoModal
                  isOpen={state.matches('Team Video.open')}
                  onClose={() => send('Close Team Video Modal')}
                />
              </motion.div>
            ) : state.matches('Media Queue.Pending') ? (
              <motion.div key="media">
                <AnalyticsContext
                  context={{ tags: [CoachingTrackingTag.LearningSession] }}
                >
                  <Fade in style={{ height: '100%' }}>
                    <SessionMedia
                      learningSession={learningSession}
                      onClose={() => send('Complete')}
                    />
                  </Fade>
                </AnalyticsContext>
              </motion.div>
            ) : state.matches('Session Practice.waiting') ? (
              <motion.div key="log-practice">
                <AnalyticsContext
                  context={{ tags: [CoachingTrackingTag.LearningSession] }}
                >
                  <LogCurrentPractice practice={currentPractice} />
                </AnalyticsContext>
              </motion.div>
            ) : state.matches('Session Practice.ending') ||
              state.matches('Session Practice.ended') ? (
              <motion.div key="free-space">
                <AnalyticsContext
                  context={{ tags: [CoachingTrackingTag.FreeSpace] }}
                >
                  <PracticeFreeSpace
                    animate={state.matches('Session Practice.ending')}
                    onAnimationComplete={() => send('Session Completed')}
                  />
                </AnalyticsContext>
              </motion.div>
            ) : null}
          </SlidePresence>
        </PageStack>
      </AnalyticsContext>
    </AppLayout>
  );
}
