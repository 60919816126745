import React from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { useSwiperSlide } from 'swiper/react';
import { match } from 'ts-pattern';

import {
  AlertBox,
  DataInsightsIcon,
  ExploreIcon,
  Icon,
  MotionBox,
  PenIcon,
  PracticeIcon,
  useGradient,
  useModalDisclosure,
  useResponsive,
  VideosIcon,
  ZStack,
} from '@arena-labs/strive2-ui';
import { queryClient, StriveApiResponse } from '@strive/api';
import { assert } from '@strive/utils';

import { useChoosePracticeSet } from './use-choose-practice-set';

export type PracticeSetCardProps = {
  tag?: React.ReactNode;
  isActive?: boolean;
} & ( // The props this component accepts depends on the `type`
  | {
      type: 'explorations';
      completedFoundations?: boolean;
    }
  | {
      type: 'pillar';
      pillar: StriveApiResponse<'getPillars'>[number];
    }
);

export function PracticeSetCard({ ...props }: PracticeSetCardProps) {
  const thisSlide = useSwiperSlide() as ReturnType<
    typeof useSwiperSlide
  > | null;

  const primaryGradient = useGradient('primary.100');
  const tertiaryGradient = useGradient('tertiary.100');

  const confirmDisclosure = useModalDisclosure('ConfirmPracticeSetSelection', {
    context: {
      type: props.type,
      pillar: props.type === 'pillar' ? props.pillar.slug : undefined,
    },
  });

  const choosePracticeSet = useChoosePracticeSet({
    onSettled() {
      queryClient.invalidateQueries({}, { cancelRefetch: false });
    },
    onSuccess() {
      confirmDisclosure.onClose();
    },
  });

  // The explorations card has a distinct background
  const bg = match(props.type)
    .with('explorations', () => ({
      bgImage: `
            ${tertiaryGradient},
            url('/images/learning-journey/bg-select-explorations.webp')
        `,
    }))
    .with('pillar', () => ({ bgGradient: primaryGradient }))
    .exhaustive();

  // The current active slide should be highlighted with a glowing border
  const shadowWidth = '25px';
  const border =
    props.isActive || thisSlide?.isActive
      ? {
          borderWidth: 4,
          borderColor: 'white',
          boxShadow: `0px 0px ${shadowWidth} 0px white`,
        }
      : { borderWidth: 2, borderColor: 'neutral.700' };

  const rs = useResponsive();

  // Show the appropriate content for the pillar or expectations
  const content = match(props)
    .with({ type: 'pillar' }, ({ pillar }) => ({
      title: pillar.short_title,
      icon: (
        <Image
          src={pillar.icon}
          alt=""
          width={rs({ base: 16, xs: 12 })}
          height={rs({ base: 16, xs: 12 })}
        />
      ),
      listTitle: `Tools you'll learn:`,
      list: pillar.practices.map((practice) => ({
        icon: <PracticeIcon slug={practice.slug} boxSize={5} />,
        label: practice.title,
      })),
      subTitle: 'Why this challenge?',
      subText: pillar.description,
      buttonText: 'Start This Challenge',
      confirmButtonText: `Start ${pillar.short_title}`,
      onSubmit: () => {
        const practiceSlug = pillar.practices[0]?.slug;
        assert(practiceSlug, 'Pillar has practices');
        choosePracticeSet.mutate({ type: 'pillar', pillarSlug: pillar.slug });
      },
    }))
    .otherwise((props) => ({
      title: 'Strive Explorations',
      icon: (
        <Icon
          size={rs({ base: 16, xs: 12 })}
          as={ExploreIcon}
          color="icon.tertiary"
        />
      ),
      listTitle: 'Suggested Daily Actions',
      list: [
        {
          icon: <Icon size="4" as={VideosIcon} color="tertiary.400" />,
          label: '1-2 videos per day',
        },
        {
          icon: <Icon size="4" as={PenIcon} color="tertiary.400" />,
          label: 'Continue tools that work for you',
        },
        {
          icon: <Icon size="4" as={DataInsightsIcon} color="tertiary.400" />,
          label: 'Move your data',
        },
      ],
      subTitle: 'Concepts for team performance',
      subText: `
                  Your physiology affects how you show up and that affects your team's
                  performance. Explorations is self paced deep dives on physiology,
                  communication and leadership.
              `,
      buttonText: 'Enter Explorations',
      confirmButtonText: 'Enter Explorations',
      onSubmit: () => choosePracticeSet.mutate({ type: 'explorations' }),
    }));

  return (
    <>
      <ZStack h="full">
        <MotionBox
          h="full"
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderRadius="10px"
          pt={rs({ base: '12', xs: 6 })}
          px="4"
          pb="6"
          gap={rs({ xs: 3, base: 5 })}
          {...bg}
          {...border}
        >
          <Text as="h2" textStyle={rs({ base: 'h2', xs: 'h3' })} color="white">
            {content.title}
          </Text>
          {content.icon}

          <Text textStyle="p1_bold" fontWeight={'bold'}>
            {content.listTitle}
          </Text>
          <List
            textStyle="p2"
            color="white"
            display="flex"
            flexDirection="column"
            gap={rs({ xs: 4, base: 6 })}
          >
            {content.list.map(({ icon, label }, index) => (
              <ListItem key={index}>
                <Flex gap="2" align="center">
                  {icon}
                  <Text
                    textStyle={rs({ base: 'p1', xs: 'p2' })}
                    fontWeight={'bold'}
                  >
                    {label}
                  </Text>
                </Flex>
              </ListItem>
            ))}
          </List>

          <Box
            h={'2px'}
            borderRadius={5}
            mb={-6}
            mt={2}
            bg={'neutral.900'}
            w={'full'}
          />

          <Box my="auto" textAlign={'center'}>
            <Text
              textStyle="p1_semibold"
              mb={2}
              fontWeight={'bold'}
              color="neutral.400"
            >
              {content.subTitle}
            </Text>
            <Text textStyle="p2" color="neutral.400" lineHeight={'tall'}>
              {content.subText}
            </Text>
          </Box>

          <Button variant="primary" onClick={confirmDisclosure.onOpen} w="full">
            {content.buttonText}
          </Button>
        </MotionBox>
        {props.tag ? (
          <Box
            alignSelf="start"
            justifySelf="center"
            transform={rs({
              base: 'translateY(-33%)',
              xs: 'translateY(-50%) scale(0.8)',
            })}
          >
            {props.tag}
          </Box>
        ) : null}
      </ZStack>
      <PracticeSetConfirmModal
        type={props.type}
        onConfirm={content.onSubmit}
        status={choosePracticeSet.status}
        {...confirmDisclosure}
      />
    </>
  );
}

type PracticeSetConfirmModalProps = UseDisclosureReturn & {
  type: 'pillar' | 'explorations' | 'explorations-final';
  onConfirm: () => void;
  status: 'idle' | 'loading' | 'success' | 'error';
};

function PracticeSetConfirmModal({
  type,
  isOpen,
  onClose,
  onConfirm,
  status,
}: PracticeSetConfirmModalProps) {
  const content = match(type)
    .with('pillar', () => ({
      title: 'Ready To Proceed?',
      description:
        'Proceeding will require you to learn all tools in this set before advancing to the next challenge.',
    }))
    .with('explorations', () => ({
      title: 'No Going Back!',
      description: `You’ll lose access to the guided daily learning, but can still revisit videos for tools in the Toolkit tab.`,
    }))
    .with('explorations-final', () => ({
      title: 'Expect A Change!',
      description: `It’s time for self guided learning. Access to tools, data and the chat remain! Enjoy exploring!`,
    }))
    .exhaustive();

  const initialRef = React.useRef(null);

  return (
    <Modal isOpen={isOpen} scrollBehavior="inside" onClose={onClose}>
      <ModalOverlay bg="blackAlpha.70" backdropFilter="none" />
      <ModalContent
        borderTopRadius={'20px'}
        marginTop="auto"
        mx="0"
        bg={'none'}
      >
        <ModalBody
          color="neutral.400"
          display="flex"
          flexDirection="column"
          gap="2"
          borderTopRadius={'20px'}
          py={4}
          bg={'elevation.4dp'}
        >
          <Text
            ref={initialRef}
            textStyle={'h2'}
            mt={4}
            color={'neutral.white'}
          >
            {content.title}
          </Text>
          <Text textStyle="p1">{content.description}</Text>

          {status === 'error' && (
            <AlertBox
              status="error"
              description="There was a problem, please try again"
            />
          )}
        </ModalBody>

        <ModalFooter
          bg={'elevation.4dp'}
          flexDirection="column"
          alignItems="stretch"
          gap="4"
          pt={1}
          pb={6}
        >
          <Box>
            <Button
              w={'full'}
              variant="secondary"
              shadow={'8dp'}
              onClick={onClose}
            >
              Back
            </Button>
          </Box>
          <Button
            variant="primary"
            onClick={() => onConfirm()}
            isLoading={status === 'loading'}
          >
            Yes!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
